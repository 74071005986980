<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span class="mainHeader"> GOLD SCHEME</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
            <v-flex xs12 md8 style="font-family: DmSansBold; font-size: 25px"
              >CUSTOMER DETAILS</v-flex
            >

            <v-flex xs12 py-6 text-left>
              <v-card
                class="rounded-lg"
                elevation="0"
                :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
                :flat="$route.name == 'Dashboard' ? true : false"
              >
                <!---------------- customer details ---------------------->
                <v-layout wrap>
                  <v-flex xs12 md10 pl-0 pl-sm-5>
                    <v-layout wrap>
                      <v-flex xs12 sm4>
                        <v-layout wrap pa-4>
                          <v-flex xs12 class="subhead2"> Customer Name </v-flex>
                          <v-flex xs12 class="subhead3" pb-4>
                            supplierName
                          </v-flex>
                          <v-flex xs12 class="subhead2"> Addess </v-flex>
                          <v-flex xs12 class="subhead3" pb-4>
                            supplierName
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-layout wrap pa-4>
                          <v-flex xs12 class="subhead2"> Mobile number </v-flex>
                          <v-flex xs12 class="subhead3" pb-4>
                            supplierName
                          </v-flex>
                          <v-flex xs12 class="subhead2"> City </v-flex>
                          <v-flex xs12 class="subhead3" pb-4>
                            supplierName
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-layout wrap pa-4>
                          <v-flex xs12 class="subhead2"> Email Address </v-flex>
                          <v-flex xs12 class="subhead3" pb-4>
                            supplierName
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <!-- ------------schems----------------- -->

                <v-layout wrap>
                  <v-flex xs12 pl-5 py-1 class="subhead3">Active Schems</v-flex>
                  <v-flex xs10 pl-4>
                    <v-expansion-panels class="rounded-lg" flat>
                      <v-expansion-panel style="background-color: #f9f6ff">
                        <v-expansion-panel-header class="expanhead">
                          Scheme Name
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <thead class="tablefont">
                              <tr>
                                <th class="tablefont">S.No.</th>
                                <th class="tablefont">Months</th>
                                <th class="tablefont">Amount Payed</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span>sd</span>
                                </td>
                                <td>
                                  <span>sd</span>
                                </td>
                                <td>
                                  <span>sd</span>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                  <v-flex xs12 md2 sm2 pl-4 pt-3 pt-md-0>
                    <v-btn @click="toggleFields()" dark color="#3F053C"
                      >Pay</v-btn
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap pa-4>
                  <v-flex xs10>
                    <v-layout wrap pa-4 justify-space-between v-if="showField1">
                      <v-flex xs12 sm5>
                        <v-flex xs12 py-1 class="subhead3">Payment Date</v-flex>
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              placeholder="Date"
                              outlined
                              color="#b234a9"
                              hide-details=""
                              dense
                              append-icon="mdi-calendar-multiselect"
                              v-bind="attrs"
                              v-on="on"
                              clearable
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            color="#571964"
                            no-title
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 sm5>
                        <v-flex xs12 py-1 class="subhead3"
                          >Scheme Monthly Installment</v-flex
                        >
                        <v-text-field
                          outlined
                          dense
                          color="#b234a9"
                          hide-details=""
                          placeholder="Installment"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-layout wrap pa-4 justify-space-between v-if="showField2">
                      <v-flex xs12 sm5>
                        <v-flex xs6 py-4 class="subhead3">
                          Payment Type
                        </v-flex>
                        <v-radio-group v-model="row" row>
                          <v-radio
                            label="Cash"
                            value="radio-1"
                            class="subhead3"
                            color="#b234a9"
                          ></v-radio>
                          <v-radio
                            label="Card"
                            value="radio-2"
                            class="subhead3"
                            color="#b234a9"
                          ></v-radio>
                        </v-radio-group>
                      </v-flex>
                      <v-flex xs5 align-self-center class="buttons1">
                        <v-btn dark color="#3F053C" class="buttons1">Save</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v- layout>
                  <v-flex xs12 class="subhead3" pl-5>Expierd Schemes</v-flex>
                  <v-flex xs10 pl-4>
                    <v-expansion-panels class="rounded-lg" flat>
                      <v-expansion-panel style="background-color: #ECEAEC">
                        <v-expansion-panel-header class="expanhead">
                          Scheme Name
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <thead class="tablefont">
                              <tr>
                                <th class="tablefont">S.No.</th>
                                <th class="tablefont">Months</th>
                                <th class="tablefont">Amount Payed</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span>sd</span>
                                </td>
                                <td>
                                  <span>sd</span>
                                </td>
                                <td>
                                  <span>sd</span>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                </v->
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      username: null,
      keyword: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      showField1: false,
      showField2: false,

      row: null,
      date: null,
      menu2: false,
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.stocklist();
      }
    },
    toggleFields() {
      this.showField1 = !this.showField1;
      this.showField2 = !this.showField2;
    },
    stocklist() {
      axios({
        url: "/stock/getalllist",
        method: "get",
        data: {
          username: this.username,
          email: this.email,
          jewelleryName: this.name,
          phone: this.phone,
          location: this.address,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentpage,
          limit: this.limit,
          from: this.from,
          to: this.to,
        },
        // params: {
        //   "x-auth-token": localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.array = response.data.data;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
